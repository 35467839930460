import React, { useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';

export default function GolfAuctionItemForm({ show, onHide, type, amount, onSubmit }) {
  const [formData, setFormData] = useState({
    sponsorName: '',
    businessName: '',
    email: '',
    phone: '',
    itemName: '',
    itemDescription: '',
    itemValue: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('/.netlify/functions/send-auction-item-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          type,
          amount
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setShowSuccess(true);
      onSubmit({
        ...formData,
      });
      
      // Clear form after successful submission
      setFormData({
        sponsorName: '',
        businessName: '',
        email: '',
        phone: '',
        itemName: '',
        itemDescription: '',
        itemValue: '',
      });
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Auction Item Sponsorship</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showSuccess ? (
          <Alert variant="success" onClose={() => setShowSuccess(false)}>
            Thank you! Your auction item has been submitted successfully.
          </Alert>
        ) : showError ? (
          <Alert variant="danger" onClose={() => setShowError(false)}>
            There was an error submitting your auction item. Please try again.
          </Alert>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Sponsor Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={formData.sponsorName}
                onChange={(e) => handleChange('sponsorName', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Business Name (if applicable)</Form.Label>
              <Form.Control
                type="text"
                value={formData.businessName}
                onChange={(e) => handleChange('businessName', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                value={formData.email}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                required
                type="tel"
                value={formData.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={formData.itemName}
                onChange={(e) => handleChange('itemName', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Item Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                value={formData.itemDescription}
                onChange={(e) => handleChange('itemDescription', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Item Value</Form.Label>
              <Form.Control
                required
                type="number"
                value={formData.itemValue}  
                onChange={(e) => handleChange('itemValue', e.target.value)}
              />
            </Form.Group>

            <Button 
              type="submit" 
              variant="primary" 
              disabled={isSubmitting} 
              style={{ borderRadius: '50rem' }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}
