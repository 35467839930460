import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

const createEmptyGolfer = () => ({
  fullName: '',
  email: '',
  phone: '',
  // isVeteran: false,
  // dietaryRestrictions: '',
  // shirtSize: ''
});

export default function GolfRegistrationForm({ show, onHide, type, onSubmit, loading }) {
  const [formData, setFormData] = useState({
    teamInfo: {
      teamName: '',
      teamContactEmail: '',
      teamContactPhone: ''
    },
    golfers: [createEmptyGolfer()]
  });

  // Update golfers array when type changes
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      golfers: type === 'foursome' 
        ? Array(4).fill(null).map(() => createEmptyGolfer())
        : [createEmptyGolfer()]
    }));
  }, [type]);

  const handleGolferChange = (index, field, value) => {
    const newGolfers = [...formData.golfers];
    newGolfers[index] = {
      ...newGolfers[index],
      [field]: value
    };
    setFormData({ ...formData, golfers: newGolfers });
  };

  const handleTeamInfoChange = (field, value) => {
    setFormData({
      ...formData,
      teamInfo: {
        ...formData.teamInfo,
        [field]: value
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  console.log(formData);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === 'foursome' ? 'Foursome Registration' : 'Individual Registration'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        {type === 'foursome' && (
            <>
          <h4>Team Information</h4>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Team Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={formData.teamInfo.teamName}
                  onChange={(e) => handleTeamInfoChange('teamName', e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Team Contact Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={formData.teamInfo.teamContactEmail}
                  onChange={(e) => handleTeamInfoChange('teamContactEmail', e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Team Contact Phone</Form.Label>
                <Form.Control
                required
                  type="tel"
                  value={formData.teamInfo.teamContactPhone}
                  onChange={(e) => handleTeamInfoChange('teamContactPhone', e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          </>
          )}
          {formData.golfers.map((golfer, index) => (
            <div key={index} className="mb-4">
              <h4>Golfer {type === 'foursome' ? index + 1 : ''} Information</h4>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={golfer.fullName}
                      onChange={(e) => handleGolferChange(index, 'fullName', e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      value={golfer.email}
                      onChange={(e) => handleGolferChange(index, 'email', e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      value={golfer.phone}
                      onChange={(e) => handleGolferChange(index, 'phone', e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Veteran/Reserve/National Guard/Active Duty"
                  checked={golfer.isVeteran}
                  onChange={(e) => handleGolferChange(index, 'isVeteran', e.target.checked)}
                />
              </Form.Group> */}

              {/* <Form.Group className="mb-3">
                <Form.Label>Dietary Restrictions</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={golfer.dietaryRestrictions}
                  onChange={(e) => handleGolferChange(index, 'dietaryRestrictions', e.target.value)}
                />
              </Form.Group> */}

              {/* Form.Select is not exported from react-boostrap 
              <Form.Group className="mb-3">
                <Form.Label>Shirt Size</Form.Label>
                <Form.Select
                  required
                  value={golfer.shirtSize}
                  onChange={(e) => handleGolferChange(index, 'shirtSize', e.target.value)}
                >
                  <option value="">Select Size</option>
                  <option value="S">Small</option>
                  <option value="M">Medium</option>
                  <option value="L">Large</option>
                  <option value="XL">X-Large</option>
                  <option value="2XL">2X-Large</option>
                  <option value="3XL">3X-Large</option>
                </Form.Select>
              </Form.Group> */}
            </div>
          ))}
          <Button type="submit" variant="primary" disabled={loading} style={{ borderRadius: '50rem' }}>
            {loading ? 'Processing...' : 'Proceed to Payment'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};