import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export default function GolfSponsorshipForm({ show, onHide, type, amount, onSubmit, loading }) {
  const [formData, setFormData] = useState({
    sponsorName: '',
    businessName: '',
    email: '',
    phone: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      sponsorshipType: type,
      amount: amount
    });
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{type} Sponsorship Registration - ${amount}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Sponsor Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={formData.sponsorName}
              onChange={(e) => handleChange('sponsorName', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Business Name (if applicable)</Form.Label>
            <Form.Control
              type="text"
              value={formData.businessName}
              onChange={(e) => handleChange('businessName', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              required
              type="tel"
              value={formData.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
          </Form.Group>

          <Button type="submit" variant="primary" disabled={loading} style={{ borderRadius: '50rem' }}>
            {loading ? 'Processing...' : 'Proceed to Payment'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
