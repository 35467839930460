import React, { useState, useEffect } from "react"
import { loadStripe } from '@stripe/stripe-js';
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import PageTitle from "../components/PageTitle/PageTitle"
import { Container, Row, Col, Card, Button, Tabs, Tab } from "react-bootstrap"
import GolfRegistrationForm from "../components/GolfTournament/GolfRegistrationForm"
import GolfSponsorshipForm from "../components/GolfTournament/GolfSponsorshipForm"
import GolfAuctionItemForm from "../components/GolfTournament/GolfAuctionItemForm"
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const SPONSORSHIP_PRICES = {
  puttingGreen: 'price_1R95YlL7wW70MYYXYRoYFLNA',
  beverageCart: 'price_1R95YjL7wW70MYYXzSKB3fti',
  closestPin: 'price_1R95YcL7wW70MYYXWHa7YkvQ',
  longestDrive: 'price_1R95YaL7wW70MYYXqeTtmFpw',
  hole: 'price_1R95YYL7wW70MYYXstb38z4I',
  platinum: 'price_1R95YzL7wW70MYYX4OwWjD3P',
  gold: 'price_1R95YxL7wW70MYYXpF2seah4',
  silver: 'price_1R95YuL7wW70MYYXfwhaqU5x',
  bronze: 'price_1R95YsL7wW70MYYX9cQTQIxf',
  singleGolfer: 'price_1R95YqL7wW70MYYXYtYFoKvS',
  lunch: 'price_1R95YnL7wW70MYYXmR5yfRVK',
  golfCart: 'price_1R95YgL7wW70MYYXfhIkE6sK',
  giftBag: 'price_1R95YUL7wW70MYYXl59FMZr9'
};

const GolfTournament = () => {
  const [spots, setSpots] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [registrationType, setRegistrationType] = useState(null)
  const [processingRegistration, setProcessingRegistration] = useState(false)
  const [showSponsorshipForm, setShowSponsorshipForm] = useState(false);
  const [selectedSponsorship, setSelectedSponsorship] = useState(null);
  const [showAuctionItemForm, setShowAuctionItemForm] = useState(false);
  const [processingSponsorshipRegistration, setProcessingSponsorshipRegistration] = useState(false);
  const [sponsorshipAvailability, setSponsorshipAvailability] = useState({});
  const [loadingAvailability, setLoadingAvailability] = useState(true);

  const handleRegistrationClick = (type) => {
    setRegistrationType(type)
    setShowRegistrationForm(true)
  }

  const handleRegistrationSubmit = async (formData) => {
    setProcessingRegistration(true);
    try {
      const stripe = await stripePromise;
      
      const priceId = registrationType === 'foursome' 
        ? 'price_1R95Z2L7wW70MYYXv1jHPypx'
        : 'price_1R95Z2L7wW70MYYXamx3b5iz';

      // Create checkout session with registration data...
      const response = await fetch('/.netlify/functions/create-golf-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          quantity: 1,
          registrationData: formData
        }),
      });

      const { sessionId, error } = await response.json();
      
      if (error) {
        console.error('Error:', error);
        return;
      }

      // Redirect to Checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setProcessingRegistration(false);
      setShowRegistrationForm(false);
    }
  };

  const handleSponsorshipClick = (type, amount, priceId) => {
    setSelectedSponsorship({ type, amount, priceId });
    setShowSponsorshipForm(true);
  };

  const handleSponsorshipSubmit = async (formData) => {
    setProcessingSponsorshipRegistration(true);
    try {
      const stripe = await stripePromise;

      // Create checkout session with sponsorship data
      const response = await fetch('/.netlify/functions/create-sponsorship-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: selectedSponsorship.priceId,
          sponsorshipData: formData
        }),
      });

      const { sessionId, error } = await response.json();
      
      if (error) {
        console.error('Error:', error);
        return;
      }

      // Redirect to Checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        console.error('Error:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setProcessingSponsorshipRegistration(false);
      setShowSponsorshipForm(false);
    }
  };

  const renderRegistrationButton = (type) => (
    <Button
      variant="primary"
      style={{
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
      }}
      disabled={loading || spots === 0}
      onClick={() => handleRegistrationClick(type)}
    >
      {loading ? "Loading..." : spots === 0 ? "Sold Out" : "Register Now"}
    </Button>
  );

  const data = useStaticQuery(graphql`
    query {
      golfImage: file(relativePath: { eq: "2025-golf-tournament.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  const DisplayAvailability = () => {
    useEffect(() => {
      fetch("/.netlify/functions/get-golf-availability")
        .then(res => res.json())
        .then(data => {
          setSpots(data.remaining_spots)
          setLoading(false)
        })
        .catch(err => {
          console.error("Error fetching availability:", err)
          setLoading(false)
        })
    }, [])

    return (
      <div className="text-center">
        <h1>Golf Tournament Registration</h1>
        {loading ? (
          <p>Checking availability...</p>
        ) : spots === 0 ? (
          <p style={{ color: "red" }}>Sold Out!</p>
        ) : (
          <p className="text-success" style={{ fontSize: "1.5rem" }}>Spots Available: {spots}</p>
        )}
      </div>
    )
  }

  useEffect(() => {
    const fetchSponsorshipAvailability = async () => {
      try {
        const response = await fetch('/.netlify/functions/get-sponsorship-availability');
        const data = await response.json();
        setSponsorshipAvailability(data);
      } catch (error) {
        console.error('Error fetching sponsorship availability:', error);
      } finally {
        setLoadingAvailability(false);
      }
    };

    fetchSponsorshipAvailability();
  }, []);

  const renderSponsorshipCard = (type, title, amount, description, initialSpots = null) => {
    const isLimited = initialSpots !== null;
    const availability = sponsorshipAvailability[type];
    const isSoldOut = isLimited && availability && availability.remaining === 0;
    const remainingSpots = availability?.remaining;

    return (
      <Card className="text-left h-100">
        <Card.Header style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          {title} - ${amount}
        </Card.Header>
        <Card.Body>
          <Card.Text className="mb-4">
            {description}
          </Card.Text>
        </Card.Body>
        {isLimited && (
          <Card.Footer className="text-muted">
            {loadingAvailability ? (
              'Checking availability...'
            ) : isSoldOut ? (
              'Sold Out'
            ) : (
              `${remainingSpots} ${remainingSpots === 1 ? 'spot' : 'spots'} remaining`
            )}
          </Card.Footer>
        )}
        <Button
          variant="primary"
          style={{
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
          }}
          disabled={loadingAvailability || isSoldOut}
          onClick={() => handleSponsorshipClick(type, amount, SPONSORSHIP_PRICES[type])}
        >
          {loadingAvailability ? 'Checking Availability...' : 
           isSoldOut ? 'Sold Out' : 'Sponsor Now'}
        </Button>
      </Card>
    );
  };

  return (
    <Layout>
      <SEO title="USS IDAHO 2025 Golf Tournament" />
      <Container fluid className="container-fluid-full">
        <PageTitle pageTitle="USS IDAHO 2025 Golf Tournament">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <div
            style={{ padding: "15px 0", height: "50vh", position: "relative" }}
          >
            <GatsbyImage
              image={data.golfImage.childImageSharp.gatsbyImageData}
              alt="Golf Tournament"
              style={{
                width: "100%",
                borderRadius: ".375rem",
                overflow: "hidden",
                height: "50vh",
              }}
              imgStyle={{
                borderRadius: ".375rem",
                height: "50vh",
              }}
            />
            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <h1
                style={{
                  color: "white",
                  fontSize: "clamp(1.5rem, 4vw, 2rem)",
                  fontWeight: "bold",
                  textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  marginBottom: "1rem",
                }}
              >
                Monday, May 5, 2025
              </h1>

              <div
                className="text-center"
                style={{
                  color: "white",
                  fontSize: "clamp(1.1rem, 3vw, 1.5rem)",
                  fontWeight: "bold",
                  textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                }}
              >
                <h3 style={{ fontSize: "inherit" }}>Registration opens at 0900</h3>
                <h3 className="mb-5" style={{ fontSize: "inherit" }}>Shotgun start at 1000</h3>
                <h3 style={{ fontSize: "inherit" }}>
                  <strong>The River Club Golf Course</strong>
                  <br />
                  6515 W State St, Garden City, ID 83714
                  <br />
                  (208) 853-4793
                </h3>
              </div>
            </div>
          </div>

          <div className="mt-5" id="golf-tournament-tabs">
            <Tabs
              defaultActiveKey="registration"
              style={{ justifyContent: "center" }}
            >
              <Tab
                eventKey="registration"
                title="Registration"
                style={{ backgroundColor: "none", justifyContent: "center" }}
                className="bg-none justify-content-center"
              >
                <Row className="mt-5 justify-content-center">
                  <Col md={12}>
                    <DisplayAvailability />
                  </Col>
                  <Col md={4}>
                    <Card className="text-left">
                      <Card.Header
                        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                      >
                        Foursome – $400
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Registration Includes:</Card.Title>
                        <Card.Text>
                          <ul>
                            <li>18-hole green fees and cart</li>
                            <li>Use of practice area</li>
                            <li>Shotgun start</li>
                            <li>Box lunch</li>
                            <li>Chance at raffle prizes</li>
                            <li>
                              Prizes for longest drive, closest to the pin, etc.
                            </li>
                          </ul>
                        </Card.Text>
                      </Card.Body>
                      {renderRegistrationButton('foursome')}
                      <GolfRegistrationForm
                        show={showRegistrationForm && registrationType === 'foursome'}
                        onHide={() => setShowRegistrationForm(false)}
                        type={registrationType}
                        onSubmit={handleRegistrationSubmit}
                        loading={processingRegistration}
                      />
                      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="text-left">
                      <Card.Header
                        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                      >
                        Individual - $125
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Registration Includes:</Card.Title>
                        <Card.Text>
                          <ul>
                            <li>18-hole green fees and cart</li>
                            <li>Use of practice area</li>
                            <li>Shotgun start</li>
                            <li>Box lunch</li>
                            <li>Chance at raffle prizes</li>
                            <li>
                              Prizes for longest drive, closest to the pin, etc.
                            </li>
                          </ul>
                        </Card.Text>
                      </Card.Body>
                      {renderRegistrationButton('individual')}
                      <GolfRegistrationForm
                        show={showRegistrationForm && registrationType === 'individual'}
                        onHide={() => setShowRegistrationForm(false)}
                        type={registrationType}
                        onSubmit={handleRegistrationSubmit}
                        loading={processingRegistration}
                      />
                      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="sponsorship" title="Sponsorship">
                <Row className="mt-4 justify-content-center">
                  <h1 style={{ marginBottom: "2rem" }}>
                    Sponsorship Opportunities
                  </h1>
                  <Col md={12}>
                    <Row className="justify-content-center h-100">
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Platinum Sponsor - $5,000
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>Two golf foursomes.</li>
                                <li>Premium start hole.</li>
                                <li>
                                  Reserved, up-front parking at tournament.
                                </li>
                                <li>
                                  Logo placement on corporate banner at
                                  clubhouse.
                                </li>
                                <li>Logo on scorecards.</li>
                                <li>Two hole sponsor signs.</li>
                                <li>
                                  Opportunity to add logoed company items to
                                  gift bags.
                                </li>
                                <li>
                                  Prominent logo placement on all promotional
                                  materials, event signage, and live
                                  leaderboard.
                                </li>
                                <li>
                                  USS IDAHO Ballcap and USS IDAHO Ship's
                                  challenge coin for each player.
                                </li>
                                
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Platinum', 5000, SPONSORSHIP_PRICES.platinum)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Gold Sponsor - $3,500
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>Two golf foursomes.</li>
                                <li>Premium start hole.</li>
                                <li>Logo placement on leader board.</li>
                                <li>One hole sponsor sign.</li>
                                <li>
                                  Opportunity to add logoed company items to
                                  gift bags.
                                </li>
                                <li>
                                  Prominent logo placement on all promotional
                                  materials, event signage, and live
                                  leaderboard.
                                </li>
                                <li>
                                  USS IDAHO Ballcap and USS IDAHO Ship's
                                  challenge coin for each player.
                                </li>
                                
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Gold', 3500, SPONSORSHIP_PRICES.gold)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Silver Sponsor - $1,500
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>One golf foursome.</li>
                                <li>One hole sponsor sign.</li>
                                <li>
                                  Opportunity to add logoed company items to
                                  gift bags.
                                </li>
                                <li>
                                  Prominent logo placement on all promotional
                                  materials and event signage.
                                </li>
                                
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Silver', 1500, SPONSORSHIP_PRICES.silver)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Bronze Sponsor - $800
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>One golf foursome.</li>
                                <li>
                                  Recognition on our website and promotional
                                  emails.
                                </li>
                                
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Bronze', 800, SPONSORSHIP_PRICES.bronze)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Single Golfer - $200
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>One golfer. Will be placed on a team.</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Single Golfer', 200, SPONSORSHIP_PRICES.singleGolfer)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Lunch Sponsor - $1,000
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>Provide lunch for the golfers.</li>
                                <li>
                                  Prominent logo placement on all promotional
                                  materials and event signage.
                                </li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Lunch', 1000, SPONSORSHIP_PRICES.lunch)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        {renderSponsorshipCard(
                          'puttingGreen',
                          'Putting Green Sponsor',
                          750,
                          <ul style={{ paddingLeft: "1rem" }}>
                            <li>Logo placement on sign on putting green.</li>
                          </ul>,
                          1
                        )}
                      </Col>
                      <Col md={4} className="mb-4">
                        {renderSponsorshipCard(
                          'beverageCart',
                          'Beverage Cart Sponsor',
                          750,
                          <ul style={{ paddingLeft: "1rem" }}>
                            <li>Logo placement on the beverage cart.</li>
                            <li>Logo placed on all promotional materials and event signage.</li>
                          </ul>,
                          1
                        )}
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Golf Cart Sponsor - $500
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>Logo placement on all carts.</li>
                                <li>
                                  Logo placed on all promotional materials and
                                  event signage.
                                </li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          {/* <Card.Footer className="text-muted">
                                  1 Available
                                </Card.Footer> */}
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Golf Cart', 500, SPONSORSHIP_PRICES.golfCart)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        {renderSponsorshipCard(
                          'closestPin',
                          'Closest to the Pin',
                          500,
                          <ul style={{ paddingLeft: "1rem" }}>
                            <li>Logo placement on pin marker sign on the green.</li>
                            <li>Logo placed on all promotional materials and event signage.</li>
                          </ul>,
                          5
                        )}
                      </Col>
                      <Col md={4} className="mb-4">
                        {renderSponsorshipCard(
                          'longestDrive',
                          'Longest Drive Sponsor',
                          500,
                          <ul style={{ paddingLeft: "1rem" }}>
                            <li>Logo placement on sign in fairway.</li>
                            <li>Logo placed on all promotional materials and event signage.</li>
                          </ul>,
                          2
                        )}
                      </Col>
                      <Col md={4} className="mb-4">
                        {renderSponsorshipCard(
                          'hole',
                          'Hole Sponsor',
                          500,
                          <ul style={{ paddingLeft: "1rem" }}>
                            <li>Logo placement on sign at hole.</li>
                            <li>Opportunity to set up a table and have live interaction with golfers.</li>
                          </ul>,
                          18
                        )}
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Gift Bag Sponsor - $250
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>
                                  Provide a gift bag or place a swag item or
                                  edible product in the gift bag provided to all
                                  players.
                                </li>
                                <li>
                                  Logo placed on all promotional materials and
                                  event signage.
                                </li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          {/* <Card.Footer className="text-muted">
                                  18 Available
                                </Card.Footer> */}
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => handleSponsorshipClick('Gift Bag', 250, SPONSORSHIP_PRICES.giftBag)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                      <Col md={4} className="mb-4">
                        <Card className="text-left h-100">
                          <Card.Header
                            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          >
                            Auction Item Sponsor
                          </Card.Header>
                          <Card.Body>
                            <Card.Text className="mb-4">
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>
                                  Donate an item valued at $50 or more for our
                                  auction.
                                </li>
                                <li>Logo placed on auction materials.</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                          <Button
                            variant="primary"
                            style={{
                              borderTopLeftRadius: "0",
                              borderTopRightRadius: "0",
                            }}
                            onClick={() => setShowAuctionItemForm(true)}
                          >
                            Sponsor Now
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </ContentBlock>
      </Container>
      <GolfSponsorshipForm
        show={showSponsorshipForm}
        onHide={() => setShowSponsorshipForm(false)}
        type={selectedSponsorship?.type}
        amount={selectedSponsorship?.amount}
        onSubmit={handleSponsorshipSubmit}
        loading={processingSponsorshipRegistration}
      />
      <GolfAuctionItemForm
        show={showAuctionItemForm}
        onHide={() => setShowAuctionItemForm(false)}
        // onSubmit={handleAuctionItemSubmit}
        // loading={processingAuctionItem}
      />
    </Layout>
  )
}

export default GolfTournament
